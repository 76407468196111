import { render, staticRenderFns } from "./shopInfo.vue?vue&type=template&id=376811d0&scoped=true&"
import script from "./shopInfo.vue?vue&type=script&lang=js&"
export * from "./shopInfo.vue?vue&type=script&lang=js&"
import style0 from "./shopInfo.vue?vue&type=style&index=0&id=376811d0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376811d0",
  null
  
)

export default component.exports